<template>
  <!-- 车载视频 -->
  <div ref="player"></div>
</template>

<script>
import config from '../../config/constantconfig';
export default {
  components: {},
  data () {
    return {
      player: null,
      dataId: '',
      channelId: '',
      appUrlT: null
    };
  },
  mounted () {
    window.getCarDetail = this.getCarDetail
    this.init()
  },
  beforeDestroy () {
    this.close()
  },
  methods: {
    getCarDetailBtn () {
      let url = '{"channelId":"1","deviceId":"18202014598"}'
      this.getCarDetail(url)
    },
    init () {
      this.player = CvNetVideo.Init(this.$refs.player, 1, {
        playerMode: 6,
        callback: () => {
          // 不加延迟，打开多个只会打开一个，解决不能打开多个视频的问题
          setTimeout(() => {
            // console.log('初始化成功==================================================================');
            // this.start()
            window.androidProxy.initWebUrl();
          }, 200);
        },
      })
      // this.start()
    },
    getCarDetail (str) {
      // console.log("接参===========================================");
      // let a = { "channelId": "1", "deviceId": "18202014603" }
      let video = JSON.parse(str)
      this.dataId = video.deviceId
      this.channelId = video.channelId
      this.start()
    },
    start () {
      if (!this.player) return
      // console.log('视频点播');
      // 视频点播
      this.player.StartRealTimeVideo(this.dataId, this.channelId, 1, true, 0, {
        clusterHost: config.socketVideoConfig.clusterHost,
        clusterPort: config.socketVideoConfig.clusterPort,
        playerMode: 6,
        events: {
          //HLS模式播放前通知 可获取RTMP HLS 等URL
          onHlsPlay: this.onHlsPlay,
          onStop: this.onStop,
        },
        callback: () => {
          this.appUrlT = setTimeout(() => {
            window.androidProxy.getVideoUrl('');
          }, 3000);
        },
      }, null, 6)
    },
    onHlsPlay (id, ucVideo, hlshttp, hlshttps, rtmpurl, cid) {
      // console.log('地址===========================================');
      console.log(hlshttp, hlshttps, rtmpurl, cid);
      if (this.appUrlT) {
        clearTimeout(this.appUrlT)
      }
      window.androidProxy.getVideoUrl(rtmpurl);
      // console.log('调用安卓方法后-----------------------');
    },
    onStop () {

    },
    close () {
      this.player = null
    },
  },
}
</script>

<style lang='scss' scoped>
</style>